import { useEffect, useState } from "react";
import { ImageSwapper } from '../ImageSwap';
import { lazy, Suspense } from "react";

const logo = require('../assets/img/logo-invertiert.webp');
const bg1 = require('../assets/img/background.webp');
const bg2 = require('../assets/img/background2.webp');
const bg3 = require('../assets/img/background3.webp');
const images = [bg1, bg2, bg3];
setTimeout(()=>{    
    images.forEach(image => {
    const imageElement = new Image();
    imageElement.src = image;
});}, 3000)
export const Home = () => {




    const [count, setCount] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount((count) => (count + 1) % images.length);
        }, 5000);
        return () => clearInterval(intervalId);
    },);

    return (
        <>

            <section id="home">
                {/* <ImageSwapper /> */}
                <div className={'bg img' + count}></div>
                {/* <img src={images[count]} alt="backgroundimage" className="home-item" /> */}


                <div className="home-container">
                    <div className='container'>

                        <div className="home-content">

                            <div className="logo">
                                <img src={logo} alt="logo" className="img-fluid" />
                            </div>
                             
                               {/* { <div className="cafe-info my-5">- Winterpause -<br></br>Wir wünschen Ihnen eine besinnliche Weihnachtszeit! <br></br><br></br>Ab 4. Januar 2025 wieder geöffnet</div>    } */}

                        </div>
                    </div>
                </div>


            </section>
            {/* <Suspense fallback={<div>Loading...</div>}>
            <Feature />
            </Suspense> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
            <Gallery />
            </Suspense> */}
        </>
    );
};